import { useState, useEffect } from "react";

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    // detect window screen width function
    const { innerWidth: width, innerHeight: height } = window;
    
    // Check if device is iPad/tablet
    const isIPad = /iPad/.test(navigator.userAgent) || 
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    // Adjust breakpoints to consider iPads as mobile
    const isDesktop = isIPad ? false : width > 768;
    const isPad = isIPad || (560 < width && width <= 768);
    const isMobile = width <= 560;
    const isSmallDevice = isMobile || isPad || isIPad;

    console.info('📏 Window Dimensions:', {
      event: 'dimension check',
      width,
      height,
      isDesktop,
      isPad,
      isMobile,
      isIPad,
      isSmallDevice,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString()
    });

    return {
      width,
      height,
      isDesktop,
      isPad,
      isMobile,
      isIPad,
      isSmallDevice,
    };
  } else {
    // For server-side rendering, default to desktop view
    // This ensures consistent rendering between server and client
    return {
      width: 1024, // Default desktop width
      height: 768, // Default desktop height
      isDesktop: true,
      isPad: false,
      isMobile: false,
      isIPad: false,
      isSmallDevice: false,
    };
  }
}

export default function useWindowDimensions() {
  // Use null as initial state to indicate dimensions haven't been measured yet
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    // Mark component as mounted
    setHasMounted(true);
    
    // Update dimensions immediately on client
    setWindowDimensions(getWindowDimensions());
    
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}