import React, { useEffect, useState } from "react"; // Add useState here
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "styled-components";
import Head from "next/head"; // Replaced react-helmet with next/head
import TagManager from "react-server-side-gtm";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
const LayoutContainer = dynamic(() => import("../components/LayoutContainer"));
import Script from "next/script";
import { ModalProvider } from "../context/modalContext";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/index.css";
import "../styles/menu-overrides.css";
import "../public/fonts/fonts.css";
import themes from "../constants/theme";
import CookieConsent from "react-cookie-consent";

import * as gtag from "../utils/gtag";
import { wrapper } from "../redux/store";
import { ScrollProvider } from "../context/ScrollProvider";
import { HeaderProvider } from "../context/HeaderProvider";
import { LocationProvider } from "../context/LocationContext"; // Import LocationProvider
import useQueryCatcher from "../hooks/useQueryCatcher";
import { storeUtmParams } from "../utils/helper";
import UTMHandler from "../components/UTMHandler";

// Progress Bar configuration.
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
// Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const tagManagerArgs = {
  gtmId: 'GTM-M8FSDVV',
  src: 'https://req.classyclosets.com',
  auth: 'uUC2cNJRIDuAgAjGn4MXwA',
  preview: 'env-1',
};

function App({ Component, pageProps }) {
  const [location, setLocation] = useState(null);
  const router = useRouter();
  useQueryCatcher(router);
  const isAdmin = router.asPath.startsWith("/admin");

  useEffect(() => {
    console.log("Initializing TagManager with args:", tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
    storeUtmParams(); // Store UTM parameters on initial load
  }, []);

  // Also store UTM parameters on route changes
  useEffect(() => {
    storeUtmParams();
  }, [router.asPath]);

  useEffect(() => {
    let visitorIdInterval = setInterval(() => {
      if (window.uniqueVisitorId) {
        console.log("Setting user data in TagManager dataLayer:", {
          user_id: window.uniqueVisitorId,
          fingerprint: window.uniqueVisitorId,
          confidenceScore: window.confidenceScore,
        });
        TagManager.dataLayer({
          dataLayer: {
            user_id: window.uniqueVisitorId,
            fingerprint: window.uniqueVisitorId,
            confidenceScore: window.confidenceScore,
          },
        });
        clearInterval(visitorIdInterval);
      }
    }, 1000);

    return () => clearInterval(visitorIdInterval); // Cleanup
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        console.log("Fetching location data...");
        const response = await fetch('/api/location');
        const data = await response.json();
        console.log("Location data fetched:", data);
        setLocation(data.closestShowroomToUser);
        TagManager.dataLayer({
          dataLayer: {
            country: data.country,
            region: data.region,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
          },
        });
        localStorage.setItem('locationFetched', JSON.stringify(data.closestShowroomToUser));
        localStorage.setItem('closestTenant', JSON.stringify(data.tenantIdLocation));
        localStorage.setItem('usersLocationData', JSON.stringify({ state: data.region, city: data.city }));
        console.log("Location data stored in localStorage");
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const storedLocation = localStorage.getItem('locationFetched');
    if (storedLocation) {
      setLocation(JSON.parse(storedLocation));
      console.log("Location data loaded from localStorage:", JSON.parse(storedLocation));
    } else {
      fetchLocation();
    }
  }, []);

  return (
    <>
      <Script
        id="fingerprint-load"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            console.info("Loading FingerprintJS Pro...");
            // Load the FingerprintJS Pro agent
            const fpPromise = import('https://fpjscdn.net/v3/gn3ZHJpr3KVXlPkeYXmG')
              .then(FingerprintJS => {
                // Initialize the agent with enhanced stability and accuracy options
                return FingerprintJS.load({
                  // Use only the default endpoints
                  scriptUrlPattern: [
                    'https://fpnpmcdn.net/v*/iife/api.js',
                    'https://fpjscdn.net/v*/iife/api.js'
                  ],
                  telemetry: false
                });
              })
              .catch(error => {
                console.error('Error loading FingerprintJS Pro module:', error);
                return null;
              });

            fpPromise
              .then(fp => {
                if (fp) {
                  console.info("FingerprintJS Pro loaded successfully");
                  // Get the visitor identifier with extended data
                  return fp.get({
                    extendedResult: true,
                    linkedId: window.location.pathname,
                    tag: { page: document.title }
                  });
                } else {
                  console.error("FingerprintJS Pro failed to load");
                  return null;
                }
              })
              .then(result => {
                if (result) {
                  console.info("FingerprintJS Pro identification complete");
                  // Store the visitor identifier and confidence score for use in forms
                  window.uniqueVisitorId = result.visitorId;
                  window.confidenceScore = result.confidence.score;
                  window.fingerprintBotProbability = result.bot?.probability || 0;
                  window.fingerprintData = result;
                } else {
                  window.uniqueVisitorId = 'null';
                  console.error('FingerprintJS Pro result is null');
                }
              })
              .catch(error => {
                window.uniqueVisitorId = 'null';
                console.error('Error getting FingerprintJS Pro result:', error);
              });
          `,
        }}
      />
      <CookiesProvider>
        <LayoutContainer>
          <LocationProvider value={location}> {/* Wrap the application with LocationProvider */}
            <ModalProvider>
              <ThemeProvider theme={themes.default}>
                <ScrollProvider>
                  <HeaderProvider>
                    <UTMHandler />
                    <Component {...pageProps} />
                  </HeaderProvider>
                </ScrollProvider>
              </ThemeProvider>
            </ModalProvider>
          </LocationProvider>
        </LayoutContainer>
      </CookiesProvider>
    </>
  );
}

export default wrapper.withRedux(App);
