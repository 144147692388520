import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { storeUtmParams } from '../../utils/helper';

const UTMHandler = () => {
  const router = useRouter();

  useEffect(() => {
    // Store UTM parameters on initial load
    storeUtmParams();

    // Listen for route changes
    const handleRouteChange = () => {
      storeUtmParams();
    };

    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup subscription
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // This is a utility component that doesn't render anything
  return null;
};

export default UTMHandler; 