import cookie from "cookie";

export function parseCookies(req) {
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
}

export function setGoogleAdwordsInfo(values) {
  if (window.globalEmail && window.globalPhone) return;
  window.globalEmail = values.email;
  window.globalPhone = `1${values.phone}`;
  return
}

export function saveToLocalStorage(key, obj, finallyCallback) {
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (e) {
    alert("You are in private browsing mode and your data may not be saved.");
  } finally {
    if (finallyCallback) finallyCallback();
  }
}

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content'
];

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

export function storeUtmParams() {
  if (typeof window === 'undefined') return;

  try {
    // Get current URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    let hasUtmParams = false;
    const currentUtmData = {};
    
    // Check for UTM parameters in URL
    UTM_PARAMS.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        currentUtmData[param] = value;
        hasUtmParams = true;
      }
    });

    // Only proceed if we found UTM parameters
    if (hasUtmParams) {
      // Get existing UTM data from localStorage
      let utmSessions = [];
      try {
        const storedData = localStorage.getItem('utm_data');
        if (storedData) {
          utmSessions = JSON.parse(storedData);
          if (!Array.isArray(utmSessions)) {
            utmSessions = [];
          }
        }
      } catch (error) {
        console.error('Error reading UTM data from localStorage:', error);
        utmSessions = [];
      }

      // Create new session entry
      const newSession = {
        ...currentUtmData,
        session_start: new Date().toISOString(),
        session_id: Math.random().toString(36).substring(2) + Date.now().toString(36)
      };

      // Check if this is a unique session
      const isUniqueSession = !utmSessions.some(session => 
        session.utm_source === newSession.utm_source &&
        session.utm_medium === newSession.utm_medium &&
        session.utm_campaign === newSession.utm_campaign &&
        session.utm_term === newSession.utm_term &&
        session.utm_content === newSession.utm_content &&
        // Only consider sessions within the last 30 minutes as duplicate
        new Date(session.session_start).getTime() > Date.now() - SESSION_TIMEOUT
      );

      if (isUniqueSession) {
        // Add to start of array (most recent first)
        utmSessions.unshift(newSession);
        console.log('Adding new UTM session:', newSession);

        // Keep only the last 10 sessions
        utmSessions = utmSessions.slice(0, 10);

        // Store back in localStorage
        localStorage.setItem('utm_data', JSON.stringify(utmSessions));
        console.log('Updated UTM sessions:', utmSessions);
      } else {
        console.log('Skipping duplicate UTM session within timeout period');
      }
    }
  } catch (error) {
    console.error('Error storing UTM parameters:', error);
  }
}

export function getCurrentSessionUtm() {
  if (typeof window === 'undefined') return null;
  
  try {
    const utmData = JSON.parse(localStorage.getItem('utm_data') || '[]');
    console.log('Retrieved UTM data from storage:', utmData[0] || 'No UTM data found');
    return utmData[0] || null; // Return most recent session or null if none exists
  } catch (error) {
    console.error('Error getting current session UTM data:', error);
    return null;
  }
}
